<template>
  <b-card-code :title="this.$route.meta.pageTitle">

    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..." /> Cargando...
    </div>
    <b-row>
      <b-col class="text-right">
        <router-link to="/vector-stats/VectorStatsNew">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
          >
            Agregar Registro
          </b-button>
        </router-link>

      </b-col>
    </b-row>
    <div v-if="rowData.length > 0">
      <b-row>
        <b-col>
          <h4 class="text-primary">{{rowData.length}} Registros</h4>
        </b-col>
        <b-col class="pb-1 text-right">

        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-input type="text" id="filter-text-box" placeholder="Buscar..." class="mb-2" v-on:input="onFilterTextBoxChanged()"></b-form-input>
        </b-col>
        <b-col md="3">
          <b-button variant="outline-primary">
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          /><span>Descargar Excel</span></b-button>
        </b-col>
        <b-col md="5" class="text-right">
          Mostrando Documentos:
          <select v-on:change="onPageSizeChanged()" id="page-size" style="padding: 2px 4px; margin: 0 4px;">
            <option value="25">25</option>
            <option value="50" selected="">50</option>
            <option value="100">100</option>
          </select>
        </b-col>
      </b-row>
      <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          :rowData="rowData"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :domLayout="'autoHeight'">
        >
      </ag-grid-vue>
    </div>
    <b-row v-if="rowData.length === 0">
      <b-col>
        No se encontraron registros en el rango de fecha.
      </b-col>
    </b-row>


    <!--    Toast-->
    <b-toast id="example-toast" variant="primary">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
              src="/logo.png"
              class="mr-1"
              height="18"
              width="18"
              alt="Sura Icon"
          />
          <strong class="mr-auto">Sura Alerta</strong>
        </div>
      </template>
      <span>La fecha <strong>Desde</strong> debe ser menor a la fecha <strong>Hasta</strong>. </span>
    </b-toast>
  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import {AgGridVue} from "ag-grid-vue";
import environment from "@/environment";
import {format, subMonths} from "date-fns";
import malariaData from '@/assets/json/malaria-stats.json'


export default {
  name: 'AuxiliaryApproval',
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BCardCode,
    AgGridVue
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      columnDefs: [
        {
          field: "FECHA",
          headerName: 'FECHA',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agDateColumnFilter'
        },
        {
          field: "ID_REGION",
          headerName: 'ID_REGION',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "REGION",
          headerName: 'REGION',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agColumnFilter'
        },
        {
          field: "PREDIOS_VIV",
          headerName: 'PREDIOS_VIV',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "PREDIOS_POS",
          headerName: 'PREDIOS_POS',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "PREDIOS_PORC",
          headerName: 'PREDIOS_PORC',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "MANZANAS_INSP",
          headerName: 'MANZANAS_INSP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "MANZANAS_POS",
          headerName: 'MANZANAS_POS',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "UTILES_ALBO",
          headerName: 'UTILES_ALBO',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "UTILES_AEG",
          headerName: 'UTILES_AEG',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "UTILES_INSP",
          headerName: 'UTILES_INSP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "UTILES_T",
          headerName: 'UTILES_T',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "INSER_ALBO",
          headerName: 'INSER_ALBO',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "INSER_AEG",
          headerName: 'INSER_AEG',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "INSERV_INSP",
          headerName: 'INSERV_INSP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "INSERV_T",
          headerName: 'INSERV_T',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "INSERV_E",
          headerName: 'INSERV_E',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "NATU_ALBO",
          headerName: 'NATU_ALBO',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "NATU_AEG",
          headerName: 'NATU_AEG',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "NATU_INSP",
          headerName: 'NATU_INSP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "NATU_T",
          headerName: 'NATU_T',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "NATU_E",
          headerName: 'NATU_E',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "TOT_ALBO",
          headerName: 'TOT_ALBO',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "TOT_AEG",
          headerName: 'TOT_AEG',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "TOT_INSP",
          headerName: 'TOT_INSP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "TOT_T",
          headerName: 'TOT_T',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "TOT_E",
          headerName: 'TOT_E',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "RATA_RECIP",
          headerName: 'RATA_RECIP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "DIAS_HOMBRE",
          headerName: 'DÍAS_HOMBRE',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "RENDIMIENTO",
          headerName: 'RENDIMIENTO',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "VIVI_NEBULIZADAS",
          headerName: 'VIVI_NEBULIZADAS',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "MANZ_NEBULIZADAS",
          headerName: 'MANZ_NEBULIZADAS',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "LEGAL_COMP",
          headerName: 'LEGAL_COMP',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },
        {
          field: "LEGAL_REC",
          headerName: 'LEGAL_REC',
          sortable: true,
          resizable: true,
          suppressSizeToFit: true,
          filter: 'agNumberColumnFilter'
        },

      ],
      rowData: '',
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        resizable: true,
      },
      paginationPageSize: null,
      link: '',
      selectedSince: null,
      selectedUntil: null,
      qSince: null,
      qUntil: null,
      showRange: null,
      searched: false,
      loading: false

    };
  },
  created() {
    // this.loadJsonData(this.$route.query.from, this.$route.query.to);
    this.rowData = malariaData
  },
  beforeMount() {
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
      return '[' + params.value.toLocaleString() + ']';
    };

  },
  methods: {
    /*loadJsonData(a, b) {
     let uri = `${environment.uri}processes/notices?`
     let since = 'qSince=' + a
     let until = 'qUntil=' + b
     if (a && b) {
       if (new Date(this.selectedSince).getTime() <= new Date(this.selectedUntil).getTime()) {
         this.$http.get(uri + since + '&' + until)
             .then(response => {
               this.rowData = response.data.records;
               this.qSince = a
               this.qUntil = b
               this.loading = false
             })
             .catch(error => {
               console.log(error);
             });
       } else {
         this.$bvToast.show('example-toast')
       }
     } else {
       this.$http.get(uri)
           .then(response => {
             this.rowData = response.data.records;
             this.loading = false
           })
           .catch(error => {
             console.log(error);
           });
     }

   },*/




    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
    },
    onPageSizeChanged() {
      const value = document.getElementById('page-size').value;
      this.gridApi.paginationSetPageSize(Number(value));
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
          document.getElementById('filter-text-box').value
      );
    },

    linkRendererDrill(id, from, to) {
      const link = document.createElement('a');
      link.href = '#';
      link.textContent = 'Detalles';
      link.className = 'detailsLink';
        link.addEventListener('click', (event) => {
          event.preventDefault();
          this.$router.push( {path: '/notices/process-log-detail/' + id,
            query: {
              from: from,
              to: to,
            },
          });
        });
        return link;
    },
    formatDateSince(a) {
      const date = new Date(a);
      const fdate = format(date, 'yyyyMMdd')
      this.qSince = fdate
    },
    formatDateUntil(b) {
      const date = new Date(b);
      const fdate = format(date, 'yyyyMMdd' )
      this.qUntil = fdate
    }
  }
}
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import "@/assets/scss/custom-ag.scss";
</style>

